import { getSession } from '@/lib/nextAuth';

type DataType = {
  [key: string]: string | number | boolean | Array<string | number | boolean> | undefined;
};

export const http = async <T>(
  path: string,
  options?: {
    /**
     * リクエストメソッド
     * Default:
     * 'GET'
     * */
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    /**
     * ヘッダー
     * Default:
     * headers = {
     *   'Content-Type': 'application/json',
     * }
     * */
    headers?: { [key: string]: string | undefined };
    /** 送信データ */
    body?: BodyInit;
    /** URLクエリ文字 */
    query?: DataType;
  },
): Promise<T> => {
  const {
    method = 'GET',
    body,
    query,
    headers = {
      'Content-Type': 'application/json',
    },
  } = options ?? {};

  const session = await getSession();

  const url = new URL(process.env.NEXT_PUBLIC_API_BASE_URL as string);

  url.pathname = path;

  if (query) {
    Object.keys(query).forEach((k) => {
      const queryValue = query[k];
      if (Array.isArray(queryValue)) {
        queryValue.forEach((v) => {
          url.searchParams.append(`${k}[]`, String(v));
        });
      } else if (queryValue != null) {
        url.searchParams.append(k, String(queryValue));
      }
    });
  }

  const res = await fetch(url.toString(), {
    method: method,
    body: body,
    headers: {
      Authorization: `Bearer ${session?.accessToken}`,
      ...headers,
    },
    next: { revalidate: 0 },
  });

  const responseJson = await res.json();

  if (responseJson.code !== 0) {
    return Promise.reject(responseJson);
  }

  return responseJson;
};
