import styles from './ToggleIconButton.module.scss';

function ToggleIconButton({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) {
  return <button className={`${styles.toggleIconButton} ${isActive ? styles.active : ''}`} onClick={onClick}></button>;
}

export default ToggleIconButton;
