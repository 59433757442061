import styles from './Badge.module.scss';

function Badge({
  children,
  color = 'primary',
  size = 'default',
}: {
  children?: React.ReactNode;
  color?: string;
  size?: 'default' | 'large';
}) {
  return (
    <div
      className={`${styles.componentStyle} ${styles[size]}`}
      style={{
        background: `var(--color-${color})`,
      }}
    >
      {children}
    </div>
  );
}

export default Badge;
