import { postOneTimeCodeCreate } from '@/api/postOneTimeCodeCreate';

/**
 * catchしたエラーを渡して、エラーメッセージを得る
 */

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const pickErrorMessages = (error: any): Array<string> => {
  if (!error) {
    return ['エラーが発生しました'];
  }

  // APIエラー
  if (error.errors && error.errors.length !== 0) {
    return Object.entries(error.errors).flatMap((obj) => {
      return obj.at(1);
    }) as Array<string>;
  }

  return [error.message];
};

/**
 * ページ遷移
 */
export const locationHref = (url: string, newTab: boolean) => {
  if (newTab) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (window as any).open('about:blank', '_blank').location.href = url;
  } else {
    location.href = url;
  }
};

/**
 * 任意の要素までスクロール
 */
export const scrollIntoTheView = (id: string) => {
  const element = document.getElementById(id) as HTMLElement;
  if (!element) return;

  const position = element.getBoundingClientRect();
  window.scrollTo({
    left: 0,
    top: position.top + window.scrollY - 100, //ヘッダー分ずらす
    behavior: 'smooth',
  });
};

// window.openがsafariで効かないので、location.hrefを用いて遷移
export const goMainOneTimeAuth = async ({
  redirectPath = '/',
  token,
}: {
  redirectPath?: string;
  token?: string;
}) => {
  if (!process.env.NEXT_PUBLIC_SERVICE_ID) return;
  const { result } = await postOneTimeCodeCreate({
    oemServiceId: process.env.NEXT_PUBLIC_SERVICE_ID,
    token: token,
  });
  const url = `${process.env.NEXT_PUBLIC_MAIN_URL}/one-time-auth?code=${result.authCode}&redirect_path=${redirectPath}`;
  location.href = url;
};
