import { http } from '@/lib/http';
import type { APIResponse } from '@/types';

export type PostSignoutResponse = APIResponse<null>;

export const postSignout = async () => {
  return await http<PostSignoutResponse>('/user/v1/logout', {
    method: 'POST',
  });
};
