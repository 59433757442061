import { http } from '@/lib/http';
import type { APIResponse } from '@/types';

export type PostOneTimeCodeCreateParams = {
  oemServiceId: string;
  token?: string;
};

export type PostOneTimeCodeCreateResponse = APIResponse<{
  authCode: string;
}>;

export const postOneTimeCodeCreate = async (params: PostOneTimeCodeCreateParams) => {
  return await http<PostOneTimeCodeCreateResponse>('/user/v1/auth/one-time-code/create', {
    method: 'POST',
    body: JSON.stringify({
      oem_service_id: params.oemServiceId,
    }),
    headers: params.token
      ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${params.token}`,
        }
      : undefined,
  });
};
