import Badge from '../Badge/Badge';
import styles from './LinkListItem.module.scss';
import FaIcon, { faChevronRight } from '../../icon/FaIcon/FaIcon';

function LinkListItem({
  iconSrc,
  text,
  showBadge = false,
  onClick,
}: {
  iconSrc: string;
  text: string;
  showBadge?: boolean;
  onClick: () => void;
}) {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a
      className={styles.componentStyle}
      href='#'
      onClick={(e) => {
        handleClick(e);
      }}
    >
      <div className={styles.iconTextWrapper}>
        <img src={iconSrc} alt='icon' className={styles.icon} />
        &nbsp; {text}
        {showBadge && (
          <div className={styles.badge}>
            <Badge color='primary'></Badge>
          </div>
        )}
      </div>
      <FaIcon icon={faChevronRight}></FaIcon>
    </a>
  );
}

export default LinkListItem;
