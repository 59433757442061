import styles from './IconTextUnit.module.scss';

type Props = {
  text: string;
  imgSrc: string;
  unit?: string;
  textSize?: 'default' | 'xLarge';
  isUnitBold?: boolean;
};

function IconTextUnit({ text, imgSrc, unit, textSize = 'default', isUnitBold = false }: Props) {
  return (
    <div className={`${styles.componentStyle} ${styles[`${textSize}Text`]}`}>
      <div className={styles.icon}>
        <img src={imgSrc} alt='' />
      </div>
      <p className={styles.text}>{text}</p>
      {unit && <span className={`${styles.unit} ${isUnitBold ? styles.boldUnit : ''}`}>{unit}</span>}
    </div>
  );
}

export default IconTextUnit;
